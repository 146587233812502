import { render, staticRenderFns } from "./CustomFooterColumnMobileTheme1.vue?vue&type=template&id=8ec501e2&scoped=true"
import script from "./CustomFooterColumnMobileTheme1.vue?vue&type=script&lang=js"
export * from "./CustomFooterColumnMobileTheme1.vue?vue&type=script&lang=js"
import style0 from "./CustomFooterColumnMobileTheme1.vue?vue&type=style&index=0&id=8ec501e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ec501e2",
  null
  
)

export default component.exports